@use 'custom-properties';
@use 'semantic-variables';
@use 'brand-background';
@use 'sportsbook';

// base/type

body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
label,
legend,
button,
input,
select,
textarea,
li,
a,
strong,
span,
th,
caption,
td,
p,
footer,
small {
	color: rgba(var(--font-light), 1);
	font-family: Outfit, sans-serif;
}

body {
	background-color: rgba(var(--primary-accent-blue-dark), 1);

	&.access-blocked {
		background-color: rgba(var(--primary-accent-blue), 1);

		h2 {
			color: rgba(var(--font-light), 1);
			font: var(--h2);
		}

		p {
			color: rgba(var(--font-supportive), 1);
		}

		a {
			color: rgba(var(--primary-accent-yellow), 1);
		}
	}
}

small {
	color: rgba(var(--font-supportive), 1);
}

// components/bonus-active

.progress {
	svg {
		path {
			stroke: rgba(var(--base-01), 1);
		}

		.progress__meter {
			stroke: rgba(var(--primary-accent-yellow), 1);
		}
	}
}

// components/buttons

.btn {
	background-color: rgba(var(--primary-accent-yellow), 1);
	color: rgba(var(--font-dark), 1);

	&:disabled {
		background-color: rgba(var(--primary-accent-yellow-light), 1);
		color: rgba(var(--font-disabled), 1);
	}

	&.btn--secondary {
		background-color: rgba(var(--primary-accent-yellow), 1);
		color: rgba(var(--font-dark), 1);
	}

	&.btn--tertiary {
		background-color: transparent;
		color: rgba(var(--font-light), 1);
	}

	&.btn--clear,
	&.btn--login,
	&.btn--signup,
	&.btn--skip,
	&.btn--round,
	&.btn--inverted {
		color: rgba(var(--font-brand), 1);
	}

	&.btn--ghost {
		color: rgba(var(--font-light), 1);
	}

	&.btn--applepay {
		-apple-pay-button-style: white;
	}

	&.btn--transparent {
		border-top: 1px solid var(--list-border-bottom);
		color: rgba(var(--font-light), 1);
	}

	&.btn--action-screen-confirm {
		background-color: rgba(var(--primary-accent-yellow), 1);
		color: rgba(var(--font-dark), 1);
	}

	&.btn--responsible-gaming-logout {
		background-color: transparent;
		color: rgba(var(--primary-accent-yellow), 1);
	}

	&.btn--transaction-history-reality-check,
	&.btn--disable-reality-check {
		background-color: transparent;
		border: solid 1px rgba(var(--primary-accent-green), 1);
		color: rgba(var(--primary-accent-green), 1);
	}
}

// components/campaign
// components/cc-form

.cc-form,
li.deposit-limit {
	&:focus-within .active-border {
		border-bottom: 1px solid rgba(var(--primary-accent-yellow), 1);
	}
}

// components/collapse
// components/detail-accordion
// components/filters

.log-in__form,
.user-options {
	border-bottom: 1px solid rgba(var(--primary-accent-blue-dark), 1);
}

.filters {
	input {
		&:checked {
			+ .filters__filter {
				color: rgba(var(--font-brand), 1);
			}
		}
	}

	.sliding-border {
		background-color: rgba(var(--primary-accent-yellow), 1);
		height: 2px;
	}
}

// components/flags
// components/form

:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus {
	box-shadow: inset 0 0 0 9999px #032547 !important;
	-webkit-text-fill-color: #fff !important;
}

::-webkit-calendar-picker-indicator {
	filter: invert(76%) sepia(93%) saturate(352%) hue-rotate(354deg) brightness(106%) contrast(101%);
}

.input-wrap {
	.input-wrap__input {
		background-color: rgba(var(--primary-accent-blue-dark), 1);
		border-bottom: 1px solid rgba(var(--primary-accent-blue-light), 1);
		color: rgba(var(--font-light), 1);

		&::placeholder {
			color: rgba(var(--font-supportive), 1);
		}

		&:focus {
			border-bottom: 1px solid rgba(var(--primary-accent-yellow), 1);
			color: rgba(var(--font-brand), 1);
		}

		&:placeholder-shown {
			border-bottom: 1px solid rgba(var(--list-border-bottom), 1);
		}

		&.input-wrap__error {
			border-bottom: 1px solid rgba(var(--error), 1);
			color: rgba(var(--error), 1);

			+ .input-wrap__label--show {
				color: rgba(var(--error), 1);
			}
		}
	}

	span.input-wrap__masked {
		.masked {
			background-color: rgba(var(--primary-accent-blue-dark), 1);
		}
	}

	&::after {
		background-color: rgba(var(--base-12), 1);
	}

	.slider-input {
		background-color: rgba(var(--primary-light), 1);
	}

	.input-wrap__label {
		color: rgba(var(--font-supportive), 1);
		left: 12px;
	}

	.input-wrap__input--large {
		background-color: rgba(var(--primary-accent-blue), 1);
	}
}

.legend,
.input-wrap__label {
	color: rgba(var(--font-supportive), 1);
}

::-webkit-input-placeholder {
	color: rgba(var(--font-supportive), 1);
}

::-moz-placeholder {
	color: rgba(var(--font-supportive), 1);
}

:-ms-input-placeholder {
	color: rgba(var(--font-supportive), 1);
}

.toggle-password {
	svg {
		fill: rgba(var(--primary-accent-yellow), 1);
	}
}

// New checkboxes

.custom-checkbox-list {
	li {
		border-bottom: 1px solid var(--list-border-bottom);
	}
}

// components/game-category
// components/game-item
// components/game-list
// components/header

.page-head {
	background-color: rgba(var(--primary-accent-blue), 1);

	.page-head__left-side {
		svg {
			fill: rgba(var(--primary-accent-yellow), 1);
		}
	}

	.page-head__title {
		color: rgba(var(--font-light), 1);
	}
}

.header-account-desktop-icon {
	.icon--sprite {
		fill: rgba(var(--base-02), 1);
	}
}

// components/german regulations header

.reg-header-wrapper {
	&.reg-head-wrapper-bg {
		background-color: rgba(var(--primary), 1);
	}

	.swipe-button-container {
		background: rgba(var(--primary-accent-blue), 1);
	}

	.swipe-button-slider {
		background: rgba(var(--info), 1);
	}

	.panic-btn_icon {
		background: rgba(var(--info), 1);
		color: rgba(var(--base-01), 1);
	}

	.panic-btn_txt,
	.desktop-panic-btn_txt {
		color: rgba(var(--base-12), 1);
	}
}

// components/german regulations header panic-reason

.account-panic-logout {
	.panic-reason-desc {
		color: rgba(var(--font-supportive), 1);
	}
}

// components/link-list

.link-list {
	.link-list__item {
		background-color: rgba(var(--primary-accent-blue-dark), 1);

		&--rounded {
			border-radius: 3px;
		}

		svg {
			fill: rgba(var(--primary-accent-blue-light), 1);
		}

		.link-list__continue-icon {
			fill: rgba(var(--primary-accent-yellow), 1);
		}

		.link-list__status-message,
		.link-list__value,
		.link-list__preamble {
			color: rgba(var(--font-supportive), 1);
		}
	}

	.link-list__more-icon {
		height: 20px;
		width: 20px;
	}
}

// components/my notifications

.notification-detail-container {
	.list-item {
		border-bottom: 1px solid var(--list-border-bottom);
	}
}

.my-notification-item-wrapper .my-notification-details .my-notification-title-bar .my-notification-title {
	color: rgba(var(--font-light), 1);
}

.cta-button-container {
	background: rgba(var(--primary-accent-blue-dark), 1);
}
// PAYMENT METHODS
.payment-methods {
	&__item {
		background-color: rgba(var(--primary-accent-blue-dark), 1);

		&__chevron {
			.link-list__continue-icon {
				fill: rgba(var(--primary-accent-yellow), 1);
			}
		}
	}
}

.js-notify__button-wrapper--failed {
	svg {
		fill: rgba(var(--primary-accent-yellow), 1);
	}
}

// dark button white text color for failed payments
.failed > .btn--secondary {
	color: rgba(var(--font-light), 1);
}

// components/modal
// components/notify

.notify--is-open {
	background-color: var(--base-modal-backdrop);

	&.reality-check-confirm {
		background-color: rgba(var(--primary), 1) !important;
		background-image: none;

		.limits-prod-switch {
			background-color: rgba(var(--primary-light), 1);
			font: initial;
		}
	}
}

.binary-question {
	.binary-question__inner {
		background-color: rgba(var(--primary-accent-blue), 1);
	}

	.binary-question__yes,
	.binary-question__no,
	.binary-question__third {
		border-top: 1px solid rgba(var(--primary-accent-blue-dark), 1);
		color: rgba(var(--font-light), 1);
	}

	.binary-question__yes {
		border-left: 1px solid rgba(var(--primary-accent-blue-dark), 1);
		border-top: 1px solid rgba(var(--primary-accent-blue-dark), 1);
	}
}

.notification-wrap {
	&.red {
		background-color: rgba(var(--error), 1);
	}

	&.green {
		background-color: rgba(var(--success), 1);
	}

	&.neutral {
		background-color: rgba(var(--info), 1);
	}
}

// PROGRESS BAR

.notify__progress-bar {
	&.notify__progress-bar--fill-success {
		background-color: rgba(var(--primary-accent-blue), 1);
		background-image: none;
	}

	&.notify__progress-bar--fill-fail {
		background-color: rgba(var(--error), 1);
		background-image: none;
	}
}

// Responsible Gaming

.my-limits-menu {
	.collapse-value {
		color: rgba(var(--font-supportive), 1);
	}
}

button.spending-limit-rg-upload {
	color: rgba(var(--secondary));
}

// Risk-profile

.risk-profile__description {
	.detail-view-header {
		p {
			color: rgba(var(--font-supportive), 1);
		}
	}
}

// components/payment-providers

.payment-tag {
	&__popular {
		background-color: rgba(var(--primary-accent-yellow), 1);
		color: rgba(var(--primary-accent-blue-dark), 1);
	}

	&__no-fee {
		background-color: rgba(var(--primary-accent-blue-light), 1);
	}
}

// components/quick-deposit

.quick-deposit-wrapper {
	background-color: rgba(var(--primary), 1);
}

.quick-deposit-response {
	&.quick-deposit-response--pending {
		background-color: rgba(var(--base-10), 1);
	}
}

// components/sidebar

.sidebar {
	.terms-conditions {
		background: rgba(var(--primary-accent-blue), 1);
	}
}

// components/scroll-page-head
// components/search

.search-icon-active + input,
.search-icon--open + input {
	color: rgba(var(--font-brand), 1);
}

.search-results__item {
	svg {
		fill: var(--search-result-item-icon);
	}
}

// components/select

.custom-select {
	select {
		background-color: rgba(var(--primary-accent-blue-dark), 1);
		border-bottom: 1px solid rgba(var(--primary-accent-blue-light), 1);
		color: rgba(var(--font-light), 1);
	}

	&::after {
		filter: brightness(0) saturate(100%) invert(83%) sepia(88%) saturate(457%) hue-rotate(332deg) brightness(101%)
			contrast(91%);
	}
}

// components/spinner
// components/support-nav

.support-nav {
	&.support-nav--is-open {
		background-color: rgba(var(--base-11), 0.8);

		.support-nav__head {
			h2,
			h3 {
				color: rgba(var(--font-light), 1);
			}
		}

		.btn--secondary {
			color: rgba(var(--font-light), 1);
		}
	}

	&.support-nav--is-open {
		background-color: rgba(var(--opacity-overlay), 0.8);

		.btn--secondary {
			background-color: rgba(var(--primary-accent-blue), 1);
			color: rgba(var(--base-01), 1);
		}
	}
}

// components/proposed-locale-nav

.propose-locale-nav {
	.propose-locale-wrapper {
		border-radius: 3px;

		.btn--secondary {
			border-bottom: 0;
			border-top: 1px solid rgba(var(--propose-locale-border, var(--base-07)), 1);
		}
	}
}

// components/tables

td {
	border-bottom: 1px solid rgba(var(--primary-accent-blue-dark), 1);

	&.u-text-r,
	p {
		color: rgba(var(--font-supportive), 1);
	}

	&:first-of-type {
		font: var(--label-bold);
	}
}

// components/tabs

.tab {
	[aria-selected='true'] {
		span {
			color: rgba(var(--primary-accent-yellow), 1);
		}

		svg {
			fill: rgba(var(--primary-accent-yellow), 1);
		}
	}
}

// layouts/account-settings

.email-verification-input-helptext {
	color: rgba(var(--font-supportive), 1);
}

.verify-mail-support-btn {
	span {
		color: rgba(var(--font-dark), 1);
	}
}

// layouts/bundles

// layouts/cookiebanner

.cookie-settings-header {
	&.u-divider {
		border-bottom-color: transparent;
	}
}

.cookie-category-wrapper {
	.u-divider {
		border-bottom-color: rgba(var(--primary-accent-blue-light), 1);
	}
}
// layouts/deposit
// layouts/footer

.seo-content__color,
.footer-legal-content {
	p,
	small {
		a,
		strong,
		em {
			color: rgba(var(--seo-read-more-btn), 1);
		}
	}
}

.paragraph-footer,
.paragraph-footer span *,
.footer-legal-content {
	color: rgba(var(--font-supportive), 1);

	a {
		color: rgba(var(--primary-accent-yellow), 1);
	}
}

footer svg.footer-logo {
	fill: rgba(var(--footer-logo-color), 1);
}

.other-logos-footer {
	&.not-payment-logos-footer li {
		background-color: transparent;
	}
}

// layouts/home
// layouts/info-box

.info-box {
	background-color: rgba(var(--base-03), 1);

	.info-box-text,
	.info-box-value {
		color: rgba(var(--font-supportive), 1);
	}
}

// layouts/ingame
// layouts/log-in
// layouts/modal-view

.modal-view {
	background-color: rgba(var(--primary-accent-blue), 1);

	.page-head {
		background-color: inherit;
	}

	&.modal-view--verified {
		background-color: rgba(var(--success), 1);
	}
}

.missing-data-dialog,
.sportsbook-relieve-limits-dialog,
.time-limits-dialog {
	background-color: rgba(var(--primary), 1);
}

// layouts/my-limits

.my-limits-menu {
	.limits-notification-wrapper {
		background-color: rgba(var(--primary-accent-blue-dark), 1);

		* {
			color: rgba(var(--font-light), 1);
		}

		small {
			color: rgba(var(--font-supportive), 1);
		}

		.icon--sprite,
		.limits-notification-icon {
			fill: rgba(var(--primary-accent-blue-light), 1);
		}
	}

	button {
		color: rgba(var(--primary-accent-yellow), 1);
	}

	.dots-container svg {
		fill: rgba(var(--primary-accent-yellow), 1);
	}
}

// layouts/off-canvas

.menu {
	&.global-menu,
	&.account-menu {
		background-color: rgba(var(--primary-accent-blue), 1);

		.link-list {
			.link-list__item {
				background-color: rgba(var(--primary-accent-blue-dark), 1);
			}
		}

		.btn--secondary {
			background-color: rgba(var(--primary-accent-blue-dark), 1);
			color: rgba(var(--font-light), 1);
		}
		// components/list-options

		.list-options {
			.link-list {
				.link-list__item {
					background-color: transparent;
					border-bottom: 1px solid rgba(var(--primary-accent-blue-dark), 1);
				}
			}
		}
	}
}
// logout modal
.logout-modal.binary-question .btn--secondary {
	background-color: rgba(var(--primary-accent-blue), 1);
}

// layouts/off-canvas-account

.last-login-timer {
	&--text,
	&--status,
	span {
		color: rgba(var(--font-supportive), 1);
	}
}

.user-balance {
	.user-balance__title {
		color: rgba(var(--font-supportive), 1);
	}
}

.session-timer {
	color: rgba(var(--font-supportive), 1);

	&.session-timer--regulation-header {
		color: rgba(var(--font-light), 1);

		.session-timer-time {
			color: inherit;
		}
	}
}

// layouts/payment
// layouts/pusher
// layouts/recover-password
// layouts/result

[class^='section-head__SubTitle'],
.u-result-header {
	color: rgba(var(--font-supportive), 1);
}

.result {
	&.result--is-open {
		.btn--secondary {
			background-color: rgba(var(--base-11), 0.25);
		}

		.js-notify__button-wrapper--failed .btn--secondary {
			background-color: rgba(var(--primary-accent-blue-dark), 1);
		}
	}
}

.result--is-open {
	.payment-result-wrapper {
		.payment-result-header {
			.payment-result__icon {
				fill: rgba(var(--base-01), 1);
			}

			/*
			h3 {
				color: var(--font-light);
			}
			*/
			p {
				color: var(--font-supportive);
			}
		}

		.u-result-header {
			color: var(--font-supportive);
		}

		/*
		.u-result {
			color: var(--font-light);
		}
		*/
	}
}

.result.result--is-open .failed-deposit {
	.btn--secondary {
		background-color: rgba(var(--primary-accent-blue-dark), 1);
	}
}

.js-payment-result-information-message {
	.js-try-again-button-header {
		color: rgba(var(--primary-accent-yellow), 1);
	}

	.js-support-button {
		background-color: rgba(var(--primary-accent-blue-dark), 1);
	}
}
// layouts/risk

.risk-tag {
	background-color: rgba(var(--base-05), 1);
}

// layouts/shop
// layouts/signup
// layouts/sportsbook
// layouts/static-text

.wrapper-about,
.wrapper-responsible-gaming,
.wrapper-privacy_policy,
.wrapper-terms-and-conditions,
.wrapper-cookie-policy {
	.adaptable-header--pinned {
		background-color: rgba(var(--primary-accent-blue), 1);
		border-bottom: transparent;
	}

	.adaptable-header--unfixed {
		--page-head-title: rgba(var(--font-light), 1);
		--page-head-link: rgba(var(--primary-accent-yellow), 1);

		background-color: rgba(var(--primary-accent-blue), 1);
		border: transparent;
	}
}

// layouts/titles

.disclaimer-text {
	color: rgba(var(--font-supportive), 1);
	text-decoration: underline;
}

// layouts/transaction-history

.transaction-history-inactive-layer {
	background-color: rgba(var(--base-11), 0.8);
}

.transaction-modal {
	background-color: rgba(var(--primary-accent-blue), 1);
}

// layouts/upload

.template-document-wrapper {
	border-bottom: 1px solid var(--list-border-bottom);

	&::after {
		background-color: rgba(var(--primary-accent-cyan), 1);
	}
}

// layouts/welcome-screen
// layouts/withdrawal

.pending-withdrawal {
	border-bottom: 1px solid var(--list-border-bottom);
}

// layouts/seo-content
// utils/placeholders
// utils/utility-classes

.u-box-bg {
	background-color: rgba(var(--primary-accent-blue), 1);
}

.u-text-grey {
	color: rgba(var(--font-supportive), 1);
}

.u-divider {
	border-bottom: 1px solid var(--list-border-bottom);
}

.custom-select-fieldset.error {
	height: 75px;
}

p.circle-progress-bar {
	font: var(--caption-bold);
}

// Transaction History

.selected-filter-tags {
	span.selected-filter-tags_text {
		color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
	}

	button::after {
		color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
	}
}

.pill.disable span {
	color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
}
